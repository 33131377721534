// framework
import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import ReactResizeDetector from "react-resize-detector";
// common
import * as shellHelper from "../../common/shell/shellHelper";
// other
import EnvironmentBadge from "./EnvironmentBadge";
import ExternalLinkView from "../../common/externalLink/ExternalLinkView";
import routeHelpers from "../../routeHelper";
import { QuestionIcon } from "@primer/octicons-react";

export default function TopNav() {
    const topNavBarRef = React.createRef<HTMLDivElement>();

    return (
        <div>
            <div ref={topNavBarRef} className="fixed-top">
                {/* note, expand=lg is right given the current top-nav content. Keep in mind the environment badge won't be visible in the normal production system */}
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Container fluid>
                        <Navbar.Brand href="/" className="px-0 py-2">
                            <img src="/favicon.png" width="40rem" height="40rem" alt="NEATS logo" className="me-2" />
                            <span style={{ fontSize: 22 }}>NEATS Public Portal</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {createNavLinks()}
                                <ExternalLinkView href={process.env.REACT_APP_NEATS_SECURE_PORTAL_URI} className="nav-item nav-link" style={navlinkStyle}>
                                    Secure Login
                                </ExternalLinkView>
                                <ExternalLinkView href="https://www.nopta.gov.au" className="nav-item nav-link" style={navlinkStyle}>
                                    NOPTA
                                </ExternalLinkView>
                                <ExternalLinkView href="https://www.offshoreregistrar.gov.au/" className="nav-item nav-link" style={navlinkStyle}>
                                    Registrar
                                </ExternalLinkView>
                            </Nav>

                            <EnvironmentBadge />
                            <Help />
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* separator bar */}
                <div className="bg-warning" style={{ height: "0.1rem" }}></div>
            </div>

            {/*
                --- whitespace padding ---
                as the Nav Bar is floating over the content, we must ensure we provide whitespace at the top of the screen that does not hide the content
            */}
            <ReactResizeDetector targetRef={topNavBarRef}>{({ width, height }) => <div style={{ height: height }}></div>}</ReactResizeDetector>
        </div>
    );
}

const navlinkStyle = {
    fontSize: 18,
    border: "0.1rem solid transparent",
    color: "white",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
};

const navlinkActiveStyle = {
    border: "0.1rem solid #FA991F",
    borderRadius: "0.3rem",
};

const navDropdownStyle = {
    color: "white",
    fontSize: 18,
    border: "0.1rem solid transparent",
};

const navDropdownlinkStyle = {
    fontSize: 18,
    color: "white",
    width: "auto",
    margin: "0.5rem",
};

const navDropdownlinkActiveStyle = {
    border: "0.1rem solid #FA991F",
    borderRadius: "0.3rem",
    backgroundColor: "#343A40",
};

function createNavLinks() {
    return (
        <>
            {createNavLink("Home", "/", true)}
            {createOpggsNavDropdown(false)}
            {createOeiNavDropdown(false)}
            {createNopimsNavDropdown(false)}
            {createNavLink("Interactive Map", "/Map", false)}
        </>
    );
}

function createNavLink(displayName: string, path: string, exact: boolean) {
    return (
        <NavLink className="nav-item nav-link" style={navlinkStyle} activeClassName="active" activeStyle={navlinkActiveStyle} exact={exact} to={path}>
            {displayName}
        </NavLink>
    );
}

function createOeiNavDropdown(exact: boolean) {
    return (
        <LinkContainer
            to="/Oei"
            style={navDropdownStyle}
            activeStyle={navlinkActiveStyle}
            onClick={(e) => {
                e.preventDefault(); // prevent a click on this LinkContainer from trying to navigate to /Oei
            }}
        >
            <NavDropdown title={"OEI Act"} menuVariant="dark" active={true}>
                <LinkContainer to="/Oei/Licence/Search" style={navDropdownlinkStyle} activeStyle={navDropdownlinkActiveStyle} exact={exact}>
                    <NavDropdown.Item className="nav-item nav-link">Licence Search</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/Oei/ApplicationTracking" style={navDropdownlinkStyle} activeStyle={navDropdownlinkActiveStyle} exact={exact}>
                    <NavDropdown.Item className="nav-item nav-link">Application Tracking</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </LinkContainer>
    );
}

function createOpggsNavDropdown(exact: boolean) {
    return (
        <LinkContainer
            to="/Opggs"
            style={navDropdownStyle}
            activeStyle={navlinkActiveStyle}
            onClick={(e) => {
                e.preventDefault();
            }}
        >
            <NavDropdown title={"OPGGS Act"} menuVariant="dark" active={true}>
                <LinkContainer to="/Opggs/Title" style={navDropdownlinkStyle} activeStyle={navDropdownlinkActiveStyle} exact={exact}>
                    <NavDropdown.Item className="nav-item nav-link">Title Search</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/Opggs/ApplicationTracking" style={navDropdownlinkStyle} activeStyle={navDropdownlinkActiveStyle} exact={exact}>
                    <NavDropdown.Item className="nav-item nav-link">Application Tracking</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/Opggs/StorageFormation" style={navDropdownlinkStyle} activeStyle={navDropdownlinkActiveStyle} exact={exact}>
                    <NavDropdown.Item className="nav-item nav-link">Register of Identified GHG Storage Formations</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </LinkContainer>
    );
}

function createNopimsNavDropdown(exact: boolean) {
    return (
        <NavLink className="nav-item nav-link" style={navlinkStyle} activeClassName="active" activeStyle={navlinkActiveStyle} exact={exact} to={routeHelpers.nopims.home()}>
            NOPIMS
        </NavLink>
    );
}

function Help(): React.ReactElement {
    const helpUrl = shellHelper.useHelpUrl();
    if (!helpUrl) return <></>;

    return (
        <a href={helpUrl} className="btn btn-outline-warning" target="_blank" rel="noopener noreferrer" title="Help">
            <QuestionIcon size="medium" />
        </a>
    );
}
